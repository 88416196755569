:root {
  --navbar-color: #000000;
  --navbar-seperator: #616161;
  --background-light: #e0e0e0;
  --background-dark: #20202f;
  --text-color-light: #353544;
  --text-color-dark: #FFFFFF;
}

.App {
  height: 100%;
  min-height: calc(100vh);
  width: calc(100vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}